<template>
  <div
    class="section-full mobile-page-padding bg-white padding-mobile bg-repeat overflow-hide"
    style="background-image: url(images/background/cross-line2.png)"
  >
    <div class="container">
      <!-- TITLE START -->

      <div class="section-head">
        <div class="sx-separator-outer separator-center">
          <div class="sx-separator bg-white bg-moving bg-repeat-x" style="background-image: url(images/background/cross-line2.png)">
            <h3 v-if="$route.query.productType === 'p-trapezoidales'" class="sep-line-one text-center">Planchas Trapezoidales</h3>
            <h3 v-if="$route.query.productType === 'p-acanaladas'" class="sep-line-one text-center">Planchas Acanaladas</h3>
            <h3 v-if="$route.query.productType === 'p-aislados'" class="sep-line-one text-center">Paneles Aislados</h3>
            <h3 v-if="$route.query.productType === 'hojalateria'" class="sep-line-one text-center">Hojalateria</h3>
          </div>
        </div>
        <div class="btn-back">
          <div class="icon-btn" @click.stop="goBack()">
            <i style="font-size: 50px; color: #7a97ab" class="fas fa-arrow-circle-left"></i>
          </div>
        </div>
      </div>
      <!-- TITLE END -->
      <!-- Trapezoidales -->`
      <div v-if="$route.query.productType === 'p-trapezoidales'">
        <div class="section-content">
          <div class="row number-block-three-outer justify-content-center">
            <div v-for="(trapezoidal, index) in trapezoidales" :key="index" class="col-xl-3 col-lg-4 col-md-6 col-sm-12 m-b30 m-t20">
              <div
                class="number-block-three slide-ani-top"
                style="-webkit-box-shadow: 0 10px 30px 0 rgba(19, 18, 18, 0.287); box-shadow: 0 10px 30px 0 rgba(31, 30, 30, 0.377)"
              >
                <div class="sx-media" style="height: 250px">
                  <img style="height: 100%" :src="trapezoidal.image" alt="" />
                </div>
                <div class="figcaption bg-gray p-a30" style="height: 130px">
                  <h4 class="m-tb0 sx-tilte">
                    {{ trapezoidal.name }}
                    <a class="site-button-link" :href="trapezoidal.ref" target="_blank">Ver Ficha Tecnica</a>
                  </h4>

                  <div class="figcaption-number animate-top-content">
                    <span>{{ index + 1 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Trapezoidales -->
      <!-- Acanaladas -->
      <div v-if="$route.query.productType === 'p-acanaladas'">
        <div class="section-content">
          <div class="row number-block-three-outer justify-content-center">
            <div v-for="(acanalada, index) in acanaladas" :key="index" class="col-xl-3 col-lg-4 col-md-6 col-sm-12 m-b30 m-t20">
              <div
                class="number-block-three slide-ani-top"
                style="-webkit-box-shadow: 0 10px 30px 0 rgba(19, 18, 18, 0.287); box-shadow: 0 10px 30px 0 rgba(31, 30, 30, 0.377)"
              >
                <div class="sx-media" style="height: 250px">
                  <img style="height: 100%" :src="acanalada.image" alt="" />
                </div>
                <div class="figcaption bg-gray p-a30" style="height: 130px">
                  <h4 class="m-tb0 sx-tilte">
                    {{ acanalada.name }}
                    <a class="site-button-link" :href="acanalada.ref" target="_blank">Ver Ficha Tecnica</a>
                  </h4>

                  <div class="figcaption-number animate-top-content">
                    <span>{{ index + 1 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Acanaladas -->
      <!-- Paneles Aislados -->
      <div v-if="$route.query.productType === 'p-aislados'">
        <div class="section-content">
          <div class="row number-block-three-outer justify-content-center">
            <div v-for="(aislado, index) in aislados" :key="index" class="col-xl-3 col-lg-4 col-md-6 col-sm-12 m-b30 m-t20">
              <div
                class="number-block-three slide-ani-top"
                style="-webkit-box-shadow: 0 10px 30px 0 rgba(19, 18, 18, 0.287); box-shadow: 0 10px 30px 0 rgba(31, 30, 30, 0.377)"
              >
                <div class="sx-media" style="height: 250px">
                  <img style="height: 100%" :src="aislado.image" alt="" />
                </div>
                <div class="figcaption bg-gray p-a30" style="height: 100px">
                  <h4 class="m-tb0 sx-tilte">
                    {{ aislado.name }}
                    <a class="site-button-link" :href="aislado.ref" target="_blank">Ver Ficha Tecnica</a>
                  </h4>

                  <div class="figcaption-number animate-top-content">
                    <span>{{ index + 1 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End paneles Aislados -->
      <!-- Hojalaterias -->
      <div v-if="$route.query.productType === 'hojalateria'">
        <div class="section-content">
          <div class="row number-block-three-outer justify-content-center">
            <div v-for="(hojalateria, index) in hojalaterias" :key="index" class="col-xl-3 col-lg-4 col-md-6 col-sm-12 m-b30 m-t20">
              <div
                class="number-block-three slide-ani-top"
                style="-webkit-box-shadow: 0 10px 30px 0 rgba(19, 18, 18, 0.287); box-shadow: 0 10px 30px 0 rgba(31, 30, 30, 0.377)"
              >
                <div class="sx-media" style="height: 250px">
                  <img style="height: 100%" :src="hojalateria.image" alt="" />
                </div>
                <div class="figcaption bg-gray p-a30" style="height: 100px">
                  <h4 class="m-tb0 sx-tilte">
                    {{ hojalateria.name }}
                    <a class="site-button-link" :href="hojalateria.ref" target="_blank">Ver Ficha Tecnica</a>
                  </h4>

                  <div class="figcaption-number animate-top-content">
                    <span>{{ index + 1 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Hojalateria -->
    </div>
  </div>
</template>
<script>
import { onMounted } from "vue";

export default {
  name: "product-type",
  setup() {
    const trapezoidales = [
      {
        name: "CCH-40",
        image: "/images/product/CCH-40.jpg",
        ref: "images/product/dataSheet/CCH-40.pdf",
      },
      {
        name: "CCH-40 CURVO",
        image: "/images/product/CCH-40CURVA.jpg",
        ref: "images/product/dataSheet/CCH-40CURVA.pdf",
      },
      {
        name: "CCH-SINDING-450",
        image: "/images/product/CCH-SINDING-450.png",
      },
      {
        name: "CCH-825",
        image: "/images/product/cch-825-sin-fondo.png",
        ref: "images/product/dataSheet/CCH-825.pdf",
      },
      {
        name: "CCH-SINDING-825",
        image: "/images/product/CCH-SINDING-825.png",
      },
      {
        name: "CCG-EMBALLETADO",
        image: "/images/product/CCG-EMBALLETADO--2.png",
        ref: "images/product/dataSheet/CCH-KR24EMBALLETADO.pdf",
      },
    ];
    const acanaladas = [
      {
        name: "CCH-SUPER ACANALADA",
        image: "/images/product/CCH-SUPERACANALADA2.png",
        ref: "images/product/dataSheet/CCH-SUPERACANALADA.pdf",
      },
      {
        name: "CCH-3/4",
        image: "/images/product/CCH-ONDA3-4.png",
        ref: "images/product/dataSheet/CCH-ONDA3-4.pdf",
      },
    ];

    const aislados = [
      {
        name: "CCH-825 AISLADO POL",
        image: "/images/product/CHH-825-AISLADOPOL2.png",
        ref: "images/product/dataSheet/CCH-825AISLAPOL.pdf",
      },
      {
        name: "CCH-40 AISLADO POL",
        image: "/images/product/CCH-40AISLADOPOL.jpg",
        ref: "images/product/dataSheet/CCH-40AISLAPOL.pdf",
      },
      {
        name: "CCH-FRIGOPOL LISO",
        image: "/images/product/CCH-FRIGOPOLLISO.png",
        ref: "images/product/dataSheet/CCH-FRIGOPOLLISO.pdf",
      },
      {
        name: "CCH-FRIGOPOL WALL",
        image: "/images/product/CCH-FRIGOPOLWALL.jpg",
        ref: "images/product/dataSheet/CCH-FRIGOPOLWALL.pdf",
      },
      {
        name: "CCH-FRIGOPOL FRISO",
        image: "/images/product/CCH-FRIGOPOLFRISOO.png",
        ref: "images/product/dataSheet/CCH-FRIGOPOLFRISO.pdf",
      },
    ];
    const hojalaterias = [
      {
        name: "CCH-CUMBRERA",
        image: "/images/product/CCH-CUMBRERA.png",
      },
      {
        name: "CCH-LIMAHOYA",
        image: "/images/product/CCH-LIMAHOYA.png",
      },
      {
        name: "CCH-CANAL U",
        image: "/images/product/CCH-CANALU.png",
      },
      {
        name: "CCH-CANALETAS",
        image: "/images/product/CCH-CANALETAS.jpg",
      },
      {
        name: "CCH-LIMATESA",
        image: "/images/product/CCH-LIMATESA.png",
      },
    ];
    onMounted(() => {
      window.scrollTo(0, 0);
    });
    const goBack = () => {
      window.history.back();
    };
    return { trapezoidales, acanaladas, aislados, hojalaterias, goBack };
  },
};
</script>
<style scoped>
.btn-back {
  position: absolute;
}
.icon-btn {
  cursor: pointer;
  width: 32px;
  position: relative;
  top: -65px;
}
.padding-mobile {
  padding: 150px 0 100px 0;
}
@media (max-width: 600px) {
  .padding-mobile {
    padding: 100px 0 70px 0;
  }
  .icon-btn {
    padding: 10px 0;
    top: 5px;
  }
}
</style>
